<template>
  <div class="home">
    <p v-if="numbered == -1">Do you want the participants numbered or labeled by your own?</p>
    <button v-if="numbered == -1" @click="numbered = 1">Numbered</button> <button v-if="numbered == -1" @click="numbered = 0">Self labeled</button>
    <textarea v-if="numbered == 0" rel="participant-textbox" cols="20" rows="5" v-model="participantsSelf" placeholder="Every line matches to one participant...(up to 2k)"></textarea><br/>
    <label v-if="numbered == 1" for="participants">How many participants?</label><br/>
    <input v-if="numbered == 1" id="participants" min="2" max="2000" v-model="participants" type="number" /><br/>
    <label v-if="numbered != -1" for="runtimeVal">Max. Runtime in seconds</label><br/>
    <input v-if="numbered != -1" id="runtimeVal" rel="runtimeInSeconds" type="number" max="60" min="15" v-model="runtime"><br/>
    <button v-if="numbered != -1" @click="setupCompleted">Setup Finished</button><br/>
    <span v-if="numbered != -1"  class="hint">(Hint: Start the Race with Enter key.)</span>
  </div>
</template>

<script>
import { mainStore } from '@/store/index'
export default {
  setup(){
    const store = mainStore();
    return {
      store
    };
  },
  name: 'GameSetup',
  data(){
    return {
      numbered: -1,
      runtime: 30,
      participantsSelf: null,
      participants: 10
    }
  },
  methods :{
    setupCompleted() {
      let uri = window.location.href.split('?');
      let ok = true;
      if(uri.length == 2) {
        let vars = uri[1].split('&');
        let getVars = {};
        let tmp = '';
        vars.forEach(function(v) {
          tmp = v.split('=');
          if(tmp.length == 2)
            getVars[tmp[0]] = tmp[1];
        });
        if(getVars["moon"] == 1){
          this.store.moon = true;
        } else {
          this.store.moon = false;
        }
      }

      if(this.runtime < 15 && this.runtime > 60){
        alert("Runtime is out of Range (15s - 60s)");
        ok = false;
      }


      if(this.numbered == 0 && this.participantsSelf == null){
        alert("Please add at least two lines of participants!");
        ok = false;
      }

        this.store.runtime = this.runtime;
        this.store.participants =  this.numbered == 0 && this.participantsSelf != null ? this.participantsSelf.split('\n').length : this.participants;
        this.store.names =  this.participantsSelf != null ? this.participantsSelf.split('\n') : null;
        if(this.participants > 2000){
          alert("Participants are out of Range (max. 2000) \n\n P.S. You will need a beefy computer.");
          ok = false;
        }

        if(ok){
          this.$emit("setupCompleted");
        }

    }
  }
}
</script>
<style>
.home{
  font-size: 20px;
  color: white;
  font-family: sans-serif;
  padding: 20px;

}

input, span.hint{
  margin-top: 10px;
  margin-bottom: 20px;
}

span.hint{
  display: block;
  font-size: 14px;

}

textarea{
  font-size: 14px;
}


button, input, textarea{
  border: none;
  background-color: white;
  color: black;
  border-radius: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 20px;
  text-transform: uppercase;
  font-family: 'Darker Grotesque', sans-serif;
  font-weight: bold;
}
</style>